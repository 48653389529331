.page__wrapper {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 30px;
}


@media screen and (max-width:650px){
  .page__wrapper {
    margin: 0;
    padding: 0;
  }
}