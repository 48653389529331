.popup__save-button {
  min-height: 50px;
  color: white;
  background-color: black;
  border: none;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  margin-top: 18px;
  cursor: pointer;
  transition: opacity .3s ease-in-out;
}

@media screen and (max-width:650px){
  .popup__save-button {
    min-height: 46px;
    font-size: 14px;
    line-height: 1.21;
    margin-top: 16px;
  }
}

.popup__save-button:hover {
  opacity: .8;
}