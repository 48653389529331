.profile__button_type_add-image {
  min-width: 150px;
  max-height: 50px;
  grid-area: add;
  background-image: url(../../../../images/icons/plus_icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  border-width: 2px;
  border-radius: 2px;
  margin-top: 35px;
}

@media screen and (max-width:650px){
  .profile__button_type_add-image {
    min-width: 100%;
    background-size: 16px;
    padding-bottom: 50px;
    margin: 36px 0 0;
  }
}