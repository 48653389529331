.image-card__remove-button {
  position: absolute;
  right: 15px;
  top: 18px;
  max-height: 19px;
  min-width: 18px;
  background-image: url(../../../images/icons/bin_icon.svg);
  background-color: transparent;
  padding-top: 19px;
  border: none;
  transition: opacity .3s ease-in-out;
}

.image-card__remove-button:hover {
  opacity: .6;
  cursor: pointer;
}