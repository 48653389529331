.image-card__image {
  position: relative;
  grid-area: image;
  box-sizing: border-box;
  padding-top: 100%;
  overflow: hidden;
  display: block;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.image-card__image:hover {
  cursor: pointer;
}
