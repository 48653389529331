.auth-component__submit {
    min-height: 50px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.21;
    margin-top: 216px;
}

.auth-component__submit:hover {
    opacity: .85;
}
