.popup {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}
