.profile__name {
  grid-area: name;
  font-weight: 500;
  font-size: 42px;
  line-height: 1.14;
  margin: 22px 0 10px;
}

@media screen and (max-width:650px){
  .profile__name {
    font-size: 22px;
    line-height: 1.23;
    margin: 0;
    justify-self: flex-end;
  }
}