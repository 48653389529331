.popup__close-button {
  min-width: 25px;
  min-height: 25px;
  position: absolute;
  top: -28px;
  right: -28px;
  background-color: transparent;
  background-image: url(../../../images/icons/close_icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: none;
  transition: opacity .3s ease-in-out;
}

@media screen and (max-width:650px){
  .popup__close-button {
    min-height: 20px;
    min-width: 20px;
    top: -40px;
    right: 0;
  }
}

.popup__close-button:hover {
  opacity: .6;
  cursor: pointer;
}