.profile {
  display: grid;
  grid-template-areas: 
  "image container add"
  "image info add";
  grid-template-columns: 150px auto 150px;
  margin: 40px 0 50px;
}

@media screen and (max-width:650px){
  .profile {
    grid-template-areas: 
    "image"
    "container"
    "info"
    "add";
    grid-template-columns: auto;
    grid-template-rows: min-content;
    justify-items: center;
    margin: 42px 19px 36px;
  }
}