.header {
  display:flex;
  justify-content: space-between;
  padding: 45px 0 41px;
  border-bottom: 1px rgba(84,84,84,.7) solid;
}

@media screen and (max-width:650px){
  .header {
    padding: 28px 0 32px;
  }
}