.auth-component__link {
    color:white;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.21;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 80px;
}

.auth-component__link:hover {
    opacity: .6;
}