.images-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px 17px;
}

@media screen and (max-width:650px){
  .images-container {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 20px;
  }
}

@media screen and (max-width:450px){
  .images-container {
    grid-template-columns: 1fr;
    margin: 0;
  }
}