.image-card {
  position: relative;
  box-sizing: border-box;
  max-width: 282px;
  max-height: 361px;
  background-color: white;
  color: black;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (max-width:450px){
  .image-card {
    margin: 0 auto;
    min-width: 282px;
    min-height: 361px;
  }
}