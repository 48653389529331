.popup__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  margin: 0 0 50px;
}

@media screen and (max-width:650px){
  .popup__title {
    font-size: 18px;
    line-height: 1.22;
  }
}